// PROD

const baseUrl = `https://dms.consumerlaw.com`;
export const caseListBaseUrl = `https://sugar.consumerlaw.com`;

// DEV

// const baseUrl = `https://nsclgdms.consumerlaw.com`;
// export const caseListBaseUrl = `https://demo-pro.nablasol.net`;

export default baseUrl;
